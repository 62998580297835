import React from "react";
import { useMobile } from "../../../hooks/useMobile";
import Style from './slide-item.module.scss';

const SlideItem = ({ slider, balance }) => {
  const { isMobile } = useMobile();

  return (
    <div style={{ width: isMobile ? '185px' : '211px' }} className={`${Style.container} mx-2`}>
      <div className="nav-slider-item">
        <img src={slider.image.small} alt="" className="rounded-circle" />
        <p className="nav-slider-des">
          {/* {slider.name}&nbsp; */}
          {slider.symbol.toUpperCase()}
          &nbsp;
          {`${slider.market_data.current_price.usd.toFixed(2)}$`}
        </p>
        <div
          className={
            slider.market_data.market_cap_change_percentage_24h >= 0
              ? "parsent blue"
              : "parsent red"
          }
        >
          <img
            src={
              slider.market_data.market_cap_change_percentage_24h >= 0
                ? "img/up.svg"
                : "img/down.svg"
            }
            alt=""
          />
          <span>
            {slider.market_data.market_cap_change_percentage_24h.toFixed(2)}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default SlideItem;