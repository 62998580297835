import React from "react";
import styled from "styled-components/macro";

const IndustryBrand = ({ icon, text }) => {
  return (
    <Container>
      <IconWrapper>
        <Icon src={icon} />
      </IconWrapper>
      <IndustryName>{text}</IndustryName>
    </Container>
  );
};

export default IndustryBrand;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  background-color: #eaf0ff;
`;

const Icon = styled.img`
  height: 70px;
  width: 70px;
`;
const IndustryName = styled.p`
  margin-top: 25px;
  max-width: 225px;
  color: #09216a;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  word-wrap: break-word;
`;
