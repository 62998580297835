import styled from "styled-components/macro";
import { map } from "lodash-es";
import HorizontalProgressLine from "../../Components/core/HorizontalProgressLine";
import IconButton from "../core/IconButton";
import { Container } from "./styled";
import { normalizeDollarAmount } from "../../utils/normalizations";

const RegularProjectCard = ({
  logo,
  name,
  banner,
  leftTime,
  clickable,
  fillPercent,
  description,
  totalRaised,
  totalRaising,
  industries,
  onClick,
  onFavorite,
}) => {
  return (
    <Container isClickable={clickable} onClick={onClick}>
      <Card>
        <CardBody>
          {banner ? (
            <Banner src={banner} alt="banner" />
          ) : (
            <BannerPlaceholder />
          )}
          {logo ? <Logo src={logo} alt="logo" /> : <LogoPlaceholder />}
          <TitleWrapper>
            <Title>{name}</Title>
            <RaisedPercentage>{fillPercent}% raised</RaisedPercentage>
          </TitleWrapper>
          <CardContent>
            <Description>{description}</Description>
            <div>
              <BadgeContainer>
                {map(industries, (industry) => (
                  <IndustryBadge key={industry}>{industry}</IndustryBadge>
                ))}
              </BadgeContainer>
              <ActionWrapper>
                <IconButton
                  clickable={false}
                  icon="/img/time-icon.svg"
                  text={leftTime}
                />
                <IconButton
                  icon="/img/heart-icon.svg"
                  text={"Add to favorite"}
                  onClick={onFavorite}
                />
              </ActionWrapper>
            </div>
          </CardContent>
        </CardBody>
        <div>
          <Divider />
          <ProgressBarWrapper>
            <StyledHorizontalProgressLine
              fillPercent={fillPercent}
              backColor="#EEF2FD"
              fillColor="#FF2853"
            />
            <RaisingValue>
              <b>{normalizeDollarAmount(totalRaised)} raised</b> of{" "}
              {normalizeDollarAmount(totalRaising)}
            </RaisingValue>
          </ProgressBarWrapper>
        </div>
      </Card>
    </Container>
  );
};

export default RegularProjectCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 531px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 10px #5d82f133;
  border-radius: 10px;
`;

const Banner = styled.img`
  height: 167px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  background-color: #eaf0ff;
`;

const BannerPlaceholder = styled.div`
  height: 167px;
  width: 100%;
  border-radius: 10px 10px 0 0;
  background-color: #eaf0ff;
`;

const Logo = styled.img`
  position: absolute;
  left: 22px;
  top: 141px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #273c7c;
`;

const LogoPlaceholder = styled.div`
  position: absolute;
  left: 22px;
  top: 141px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #273c7c;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 118px;
  margin-top: 6px;
`;

const Title = styled.p`
  color: #000f3c;
  font-family: Poppins;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
`;

const RaisedPercentage = styled.p`
  color: #000f3c;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const Description = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines to display */
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 20px;
  color: #000f3c;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  word-wrap: break-word;
  width: 340px;

  @media (max-width: 1720px) {
    width: 320px;
  }

  @media (max-width: 728px) {
    width: 80vw;
  }
`;

const Divider = styled.hr`
  margin: 0;
  border-top: 2px solid #273c7c;
  color: #273c7c80;
`;

const StyledHorizontalProgressLine = styled(HorizontalProgressLine)`
  box-sizing: content-box;
  height: 10px;
`;

const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const RaisingValue = styled.p`
  margin-top: 13px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: right;
  color: #000f3c;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-left: 30px;
  margin-bottom: 24px;
`;

const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 22px;
`;

const IndustryBadge = styled.div`
  padding: 4px 11px 4px 11px;
  border-radius: 2px;
  background-color: #eef2fd;
  color: #273c7c;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
