import React from "react";
import styled from "styled-components/macro";

const IconButton = ({ icon, text, clickable = true }) => {
  return (
    <Wrapper isClickable={clickable}>
      {!!icon && <Icon src={icon} />}
      {!!text && <Text>{text}</Text>}
    </Wrapper>
  );
};

export default IconButton;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
  color: #273c7c;
`;

const Text = styled.p`
  padding-left: 6px;
  color: #273c7c;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
`;
