import React, { useCallback } from "react";
import RegularProjectCard from "./RegularProjectCard";
import CompactProjectCard from "./CompactProjectCard";
import { useNavigate } from "react-router-dom";
import { useAuthenticated } from "../../hooks/useAuthenticated";
import { getTimeRemainingStringShort } from "../../utils/projectUtils";

const ProjectCard = ({
  logo,
  name,
  size = "regular",
  banner,
  endTime,
  totalRaised,
  totalRaising,
  description,
  fillPercent,
  projectId,
  typeOfIndustry = [],
}) => {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuthenticated();

  const timeRemainingString = getTimeRemainingStringShort(endTime);

  const handleClickOnCard = useCallback(() => {
    isAuthenticated && navigate(`/company-page/${projectId}`);
  }, [isAuthenticated, projectId, navigate]);

  const handleAddFavorite = useCallback(() => {
    // add project to favorite
  }, []);

  return (
    <>
      {size === "regular" ? (
        <RegularProjectCard
          logo={logo}
          name={name}
          banner={banner}
          clickable={isAuthenticated}
          fillPercent={fillPercent}
          description={description}
          totalRaised={totalRaised}
          totalRaising={totalRaising}
          leftTime={timeRemainingString}
          industries={typeOfIndustry}
          onClick={handleClickOnCard}
          onFavorite={handleAddFavorite}
        />
      ) : (
        <CompactProjectCard
          logo={logo}
          name={name}
          clickable={isAuthenticated}
          fillPercent={fillPercent}
          description={description}
          totalRaising={totalRaising}
          onClick={handleClickOnCard}
          onFavorite={handleAddFavorite}
        />
      )}
    </>
  );
};

export default ProjectCard;
