import React from "react";
import { useSelector } from "react-redux";
import { map } from "lodash";
import ProjectCard from "../../Components/project-card/ProjectCard";
import { ProjectsWrapper, SectionTitle, ShowMoreButton } from "./shared";
import { filteredProjectsSelector } from "../../store/selectors/projects";
import { useClientPagination } from "../../hooks/useClientPagination";
import { useWindowSize } from "../../hooks/useWindowSize";
import { fundingTypes, screenSizes } from "../../consts";
import { toLower } from "lodash-es";

const ProjectsList = () => {
  const { width } = useWindowSize();
  const projects = useSelector(filteredProjectsSelector);

  const { slicedData, viewAll, isHasMore } = useClientPagination({
    pageSize: width > screenSizes.xlarge ? 8 : 9,
    allData: projects,
  });

  if (!slicedData?.length) {
    return null;
  }

  return (
    <>
      <SectionTitle>{slicedData?.length || 0} projects</SectionTitle>

      <ProjectsWrapper>
        {map(
          slicedData,
          ({
            logo,
            banner,
            projectName,
            totalRaising,
            totalRaised,
            endTime,
            id,
            fundingType,
            tokenAmount,
            description,
            typeOfIndustry,
            availableAmount,
            maxAllocation,
          }) => {
            let fillPercent;

            if (toLower(fundingType) === fundingTypes.token) {
              fillPercent = Math.round(
                ((parseInt(tokenAmount) - parseInt(availableAmount)) /
                  parseInt(tokenAmount)) *
                  100
              );
            } else {
              fillPercent = parseInt((totalRaised / totalRaising) * 100);
            }

            return (
              <ProjectCard
                maxAllocation={maxAllocation}
                key={id}
                logo={logo}
                banner={banner}
                name={projectName}
                endTime={endTime}
                totalRaised={totalRaised}
                totalRaising={totalRaising}
                description={description}
                projectId={id}
                fillPercent={fillPercent}
                typeOfIndustry={typeOfIndustry}
              />
            );
          }
        )}
      </ProjectsWrapper>

      {isHasMore && <ShowMoreButton onClick={viewAll}>Show All</ShowMoreButton>}
    </>
  );
};

export default ProjectsList;
