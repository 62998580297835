import React from "react";
import styled, { css } from "styled-components/macro";
import Button from "../../Components/core/Button";

export const CardRow = ({ field, value, isClosed }) => (
  <Row>
    <Field>{field}</Field>
    <Value isClosed={isClosed}>{value}</Value>
  </Row>
);

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000f3c;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 9px;
  }
`;

const Field = styled.div`
  font-weight: 300;
`;

const Value = styled.div`
  font-weight: 600;

  ${({ isClosed }) =>
    isClosed &&
    css`
      color: #ff2853;
    `}
`;

export const ShowMoreButton = styled(Button)`
  color: white;
  background-color: #273c7c;
  margin: auto;
  width: 153px;
  height: 45px;
`;

export const SectionTitle = styled.div`
  color: #273c7c80;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 43px;

  @media (max-width: 1280px) {
    margin-bottom: 27px;
  }
`;

export const Logo = styled.img`
  width: 56px;
  height: 32px;
  margin-bottom: 30px;

  @media (max-width: 1920px) {
    width: 43px;
    height: 28px;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    width: 23px;
    height: 15px;
    margin-bottom: 13px;
  }
`;

export const Name = styled.div`
  color: #000f3c;
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  margin-bottom: 24px;

  @media (max-width: 1920px) {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;

export const ProjectsWrapper = styled.div`
  display: grid;
  grid-gap: 39px 39px;
  grid-template-columns: repeat(4, 1fr);
  justify-content: flex-start;
  margin: auto;
  margin-bottom: 104px;
  max-width: 1720px;

  @media (max-width: 1720px) {
    grid-gap: 26px 26px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 70px;
  }

  @media (max-width: 1080px) {
    grid-gap: 26px 26px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 46px;
  }

  @media (max-width: 728px) {
    grid-gap: 13px 13px;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 32px;
  }
`;
