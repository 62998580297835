import { createSelector } from "reselect";
import { filter, keyBy } from "lodash";
import {
  getFuzzySearchResults,
  getProjectOpenStatus,
  isProjectFitsFilter,
} from "../../utils/projectUtils";
import { fundingTypes, projectOpenStatuses } from "../../consts";
import { filterValuesSelector } from "./filters";
import { searchSelector } from "./search";
import { toLower } from "lodash-es";

export const projectsSelector = (state) => state.projects;

export const projectsListSelector = createSelector(
  [projectsSelector],
  (projects) => keyBy(projects?.projectsList, "id")
);

export const tokenProjectsListSelector = createSelector(
  [projectsListSelector],
  (projects) => {
    const tokenProjects = filter(
      projects,
      ({ fundingType }) => toLower(fundingType) === fundingTypes.token
    );

    return keyBy(tokenProjects, "id");
  }
);

export const filteredProjectsSelector = createSelector(
  [projectsListSelector, filterValuesSelector, searchSelector],
  (projects, filterValues, search) => {
    const projectsAfterFilter = filter(projects, (project) =>
      isProjectFitsFilter({ project, filterValues })
    );

    return getFuzzySearchResults({
      originalData: projectsAfterFilter,
      inputValue: search,
      searchFields: ["projectName", "description", "other_industry_type"],
    });
  }
);

export const openFilteredProjectsSelector = createSelector(
  [filteredProjectsSelector],
  (projectsList) =>
    filter(
      projectsList,
      (project) => getProjectOpenStatus(project) === projectOpenStatuses.open
    )
);

export const upcomingFilteredProjectsSelector = createSelector(
  [filteredProjectsSelector],
  (projectsList) =>
    filter(
      projectsList,
      (project) =>
        getProjectOpenStatus(project) === projectOpenStatuses.upcoming
    )
);

export const closedFilteredProjectsSelector = createSelector(
  [filteredProjectsSelector],
  (projectsList) =>
    filter(
      projectsList,
      (project) => getProjectOpenStatus(project) === projectOpenStatuses.closed
    )
);
