import { useState } from "react";
import { slice } from "lodash";

export const useClientPagination = ({ pageSize, allData }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const currentLastIndex = pageSize * currentPage;
  const isHasMore = allData?.length > currentLastIndex;
  const slicedData = slice(allData, 0, currentLastIndex);

  const viewNextPage = () => setCurrentPage(currentPage + 1);

  const viewAll = () => setCurrentPage((allData?.length || 0) / pageSize);

  const resetCurrentPage = () => setCurrentPage(1);

  return { slicedData, viewNextPage, viewAll, isHasMore, resetCurrentPage };
};
