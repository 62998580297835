import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import Header from "./Header";
import Navbar from "../../Components/Section/Navbar";
import IndustryBrand from "../../Components/core/IndustryBrand";
import ProjectsList from "./ProjectsList";
import Footer from "../../Components/Section/Footer";
import { setSearch } from "../../store/search/actions";
import { clearFilters } from "../../store/filters/actions";
import SearchSection from "./SearchSection";
import PaddingBox from "../../Components/PaddingBox";

const Launchpad = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setSearch(""));
      dispatch(clearFilters());
    };
  }, [dispatch]);

  return (
    <Container>
      <Navbar status={"launchpad"} />
      <Header />
      <ProjectsWrapper>
        <IndustryBrandWrapper>
          <IndustryBrand
            icon="/img/repair-icon.svg"
            text="Automobiles and Components"
          />
          <IndustryBrand
            icon="/img/bank-icon.svg"
            text="Banks & Capital Goods"
          />
          <IndustryBrand
            icon="/img/repair-icon.svg"
            text="Commercial and Professional Services"
          />
          <IndustryBrand
            icon="/img/cloth-icon.svg"
            text="Consumer Durables and Apparel"
          />
          <IndustryBrand icon="/img/rocket-icon.svg" text="Consumer Services" />
          <IndustryBrand
            icon="/img/dollar-icon.svg"
            text="Diversified Financials"
          />
        </IndustryBrandWrapper>
        <SearchSection />
        <ProjectsList />
      </ProjectsWrapper>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProjectsWrapper = styled(PaddingBox)`
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 58px;
  padding-bottom: 84px;

  @media (max-width: 1920px) {
    padding-top: 65px;
    padding-bottom: 101px;
  }

  @media (max-width: 1280px) {
    padding-top: 51px;
    padding-bottom: 60px;
  }

  @media (max-width: 768px) {
    padding-top: 37px;
    padding-bottom: 49px;
  }
`;

const IndustryBrandWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 70px;
  margin-bottom: 80px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
`;

export default Launchpad;
