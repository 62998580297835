import React, { useState } from "react";
import { keys } from "lodash";
import styled from "styled-components/macro";
import config from "../assets/config.json";
import { useSelector, useDispatch } from "react-redux";
import Dropdown from "./core/formElements/dropdown/Dropdown";
import { getCountryOptions } from "../utils/normalizations";
import Button from "./core/Button";
import { buttonVariants, projectFilterTypes, screenSizes } from "../consts";
import { useWindowSize } from "../hooks/useWindowSize";
import { clearFilters, setFilter } from "../store/filters/actions";
import { filtersSelector } from "../store/selectors/filters";

const countryOptions = [{ label: "All", value: "All" }, ...getCountryOptions()];

const FiltersArea = ({ className, filterTypes }) => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const filters = useSelector(filtersSelector);

  const filterTypeKeys = keys(filterTypes);
  const isSmallScreen = width <= screenSizes.medium;

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setIsOpen(false);
    filterTypeKeys.forEach((key) => {
      dispatch(setFilter({ type: key, data: e.target[key] }));
    });
  };

  const renderIcon = () => (
    <FilterIconWrapper onClick={() => setIsOpen(true)}>
      <FilterIcon src="/img/filter.svg" />
    </FilterIconWrapper>
  );

  const renderContent = () => (
    <ContentWrapper>
      <CloseIcon src="/img/close-dark.svg" onClick={() => setIsOpen(false)} />

      <Title>Filter Options</Title>
      <FilterWrapper onSubmit={handleFilterSubmit}>
        <DropdownsContainer className="dropdowns-container">
          {filterTypeKeys.includes(projectFilterTypes.projectStatus) && (
            <StyledDropdown
              label="Project status"
              placeholder="Select project status"
              name={projectFilterTypes.projectStatus}
              options={config.projectStatusOptions}
              {...filterTypes[projectFilterTypes.projectStatus]}
            />
          )}

          {filterTypeKeys.includes(projectFilterTypes.typeOfInvestment) && (
            <StyledDropdown
              label="Investment type"
              placeholder="Select Investment type"
              name={projectFilterTypes.typeOfInvestment}
              options={config.signUp.company.companyTypeOptions}
              {...filterTypes[projectFilterTypes.typeOfInvestment]}
            />
          )}

          {filterTypeKeys.includes(projectFilterTypes.typeOfIndustry) && (
            <StyledDropdown
              label="Industry"
              placeholder="Select Industry"
              name={projectFilterTypes.typeOfIndustry}
              options={config.signUp.investor.companyInfo.typeOfIndustryOptions}
              {...filterTypes[projectFilterTypes.typeOfIndustry]}
            />
          )}

          {filterTypeKeys.includes(projectFilterTypes.companyStage) && (
            <StyledDropdown
              label="Company stage"
              placeholder="Select Company stage"
              name={projectFilterTypes.companyStage}
              options={
                config.signUp.company.tokenCompanyInfo.companyStageOptions
              }
              {...filterTypes[projectFilterTypes.companyStage]}
            />
          )}

          {filterTypeKeys.includes(projectFilterTypes.countryOfCooperation) && (
            <StyledDropdown
              label="Country of cooperation"
              placeholder="Select Country"
              name={projectFilterTypes.countryOfCooperation}
              options={countryOptions}
              isMulti
              {...filterTypes[projectFilterTypes.countryOfCooperation]}
            />
          )}
        </DropdownsContainer>
        <StyledViewButton variant={buttonVariants.primary} type="submit">
          View
        </StyledViewButton>
        <ButtonsWrapper>
          <Button
            variant={buttonVariants.primary}
            type="submit"
            onSubmit={handleFilterSubmit}
          >
            Search
          </Button>

          <Clear onClick={() => dispatch(clearFilters())}>Clear filter</Clear>
        </ButtonsWrapper>
      </FilterWrapper>
    </ContentWrapper>
  );

  return (
    <Container className={className}>
      {isOpen || !isSmallScreen ? renderContent() : renderIcon()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const FilterWrapper = styled.form`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 1280px) {
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 8px;
  }
`;

const FilterIconWrapper = styled.div`
  width: 42px;
  height: 46px;
  background: #ffffff;
  border-radius: 8px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FilterIcon = styled.img`
  width: 14px;
  height: 15px;
`;

const ContentWrapper = styled.div`
  flex-direction: row;
  padding: inherit;
  width: 100%;
  height: inherit;
  background-color: inherit;
  position: inherit;

  @media (max-width: 768px) {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    background-color: #f3f7ff;
    padding: 86px 48px 59px 19px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
  }
`;

const CloseIcon = styled.img`
  display: none;

  @media (max-width: 768px) {
    display: initial;
    position: absolute;
    width: 17px;
    height: 17px;
    top: 35px;
    right: 26px;
    cursor: pointer;
  }
`;

const Title = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: initial;
    font-weight: 500;
    font-size: 20px;
    color: #09216a;
    margin-bottom: 31px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  min-width: 191px;
`;

const DropdownsContainer = styled.div`
  display: grid;
  grid-gap: 36px;
  flex: 1;
  align-items: flex-end;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: normal;
    gap: 20px;
  }
`;

const ButtonsWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-top: auto;
    align-items: center;
    width: 100%;
  }
`;

const StyledViewButton = styled(Button)`
  height: 50px;
  width: 160px;
  margin-left: 56px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Clear = styled.div`
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
`;

export default FiltersArea;
